import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PrivacyPolicyContent from "../components/PrivacyPolicy/privacy-policy-content"

const PriceListPage = () => (
  <Layout>
    <Seo title={"Polityka prywatności"} description={"Dbamy o Twoją prywatność. Zapoznaj się z polityką prywatności i przekonaj się sam."} />
    <div data-g="container">
      <PrivacyPolicyContent />
    </div>
  </Layout>
)

export default PriceListPage

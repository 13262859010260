import * as React from "react"
import { Link } from "gatsby"

const PricelistHero = () => {
  return (
    <div className={"privacy-policy-content"}>
      <h1>Polityka prywatności</h1>
      <div data-g="grid">
        <div data-g="4 12@sm 6@md">
          <p>
            1. Dla Właściciela niniejszej strony internetowej, ochrona danych
            osobowych Użytkowników jest sprawą najwyższej wagi. Dokłada on ogrom
            starań, aby Użytkownicy czuli się bezpiecznie, powierzając swoje
            dane osobowe w trakcie korzystania ze strony internetowej.
          </p>
          <p>
            2. Użytkownik to osoba fizyczna, osoba prawna albo jednostka
            organizacyjna, nieposiadająca osobowości prawnej, której ustawa
            przyznaje zdolność prawną, korzystająca z usług elektronicznych,
            dostępnych w ramach strony internetowej.
          </p>
        </div>
        <div data-g="4 12@sm 6@md">
          <p>
            3. Niniejsza polityka prywatności wyjaśnia zasady i zakres
            przetwarzania danych osobowych Użytkownika, przysługujące mu prawa,
            jak też obowiązki administratora tych danych, a także informuje o
            używaniu plików cookies.
          </p>
          <p>
            4. Administrator stosuje najnowocześniejsze środki techniczne i
            rozwiązania organizacyjne, zapewniające wysoki poziom ochrony
            przetwarzanych danych osobowych oraz zabezpieczenia przed dostępem
            osób nieupoważnionych.
          </p>
        </div>
      </div>

      <hr className="separator"></hr>

      <div data-g="grid">
        <div data-g="4 12@sm 6@md">
          <h2>Administrator danych osobowych</h2>
          <p>
            Administratorem danych osobowych jest Przedsiębiorca Pan Jarosław
            Cencyk, prowadzący działalność gospodarczą pod firmą: Taxi
            Prawobrzeże sp z.o.o, z siedzibą przy: ul. A. Struga 44, 70-784,
            Szczecin, NIP: 9551507490 (zwany dalej: „Właściciel").
          </p>
          <p>
            1. Dla Właściciela niniejszej strony internetowej, ochrona danych
            osobowych Użytkowników jest sprawą najwyższej wagi. Dokłada on ogrom
            starań, aby Użytkownicy czuli się bezpiecznie, powierzając swoje
            dane osobowe w trakcie korzystania ze strony internetowej. 2.
            Użytkownik to osoba fizyczna, osoba prawna albo jednostka
            organizacyjna, nieposiadająca osobowości prawnej, której ustawa
            przyznaje zdolność prawną, korzystająca z usług elektronicznych,
            dostępnych w ramach strony internetowej.
          </p>

          <h2>Cel przetwarzania danych osobowych</h2>
          <p>
            1. Administrator przetwarza dane osobowe Użytkownika w celu: W celu
            obliczenia orientacyjnego kosztu przejazdu i obsługi formularza
            dotyczącego zgłoszeń do pracy w charakterze kierowcy lub
            dyspozytora.
          </p>
          <p>
            2. Użytkownik może również wyrazić zgodę na otrzymywanie informacji
            o nowościach i promocjach, co spowoduje, że administrator będzie
            również przetwarzać dane osobowe, w celu przesyłania Użytkownikowi
            informacji handlowych, dotyczących m.in. nowych produktów lub usług,
            promocji czy wyprzedaży.
          </p>
          <p>
            3. Dane osobowe są również przetwarzane w ramach wypełnienia
            prawnych obowiązków, ciążących na administratorze danych oraz
            realizacji zadań, w interesie publicznym m.in. do wykonywania zadań,
            związanych z bezpieczeństwem i obronnością lub przechowywaniem
            dokumentacji podatkowej.
          </p>
          <p>
            4. Dane osobowe mogą być również przetwarzane w celach marketingu
            bezpośredniego produktów, zabezpieczenia i dochodzenia roszczeń lub
            ochrony przed roszczeniami Użytkownika lub osoby trzeciej, jak
            również marketingu usług i produktów podmiotów trzecich lub
            marketingu własnego, niebędącego marketingiem bezpośrednim.
          </p>

          <h2>Rodzaj danych</h2>
          <p>
            1. Administrator przetwarza następujące dane osobowe, których
            podanie jest niezbędne do: a. Dane podawane przez Użytkownika
            opcjonalnie: - data urodzenia;
          </p>

          <h2>Podstawa prawna przetwarzania danych osobowych</h2>

          <p>
            1. Dane osobowe są przetwarzane zgodnie z przepisami Rozporządzenia
            Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
            2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem
            osobowych i w sprawie swobodnego przepływu takich danych oraz
            uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
            danych), OJ L 119, 4.5.2016, p. 1–88, dalej zwane: „rozporządzenie
            RODO".
          </p>
          <p>
            2. Administrator przetwarza dane osobowe wyłącznie po uprzednim
            uzyskaniu zgody Użytkownika.
          </p>
          <p>
            3. Wyrażenie zgody na przetwarzanie danych osobowych jest całkowicie
            dobrowolne.
          </p>

          <h2>Prawa przysługujące użytkownikowi</h2>

          <p>
            1. Użytkownik może w każdej chwili zażądać od administratora
            informacji o zakresie przetwarzania danych osobowych. danych
          </p>
          <p>
            2. Użytkownik może w każdej chwili zażądać poprawienia bądź
            sprostowania swoich danych osobowych.
          </p>
          <p>
            3. Użytkownik może w każdej chwili wycofać swoją zgodę na
            przetwarzanie jego danych osobowych, bez podawania przyczyny.
            Żądanie nieprzetwarzania danych może dotyczyć wskazanego przez
            Użytkownika konkretnego celu przetwarzania np. wycofanie zgody na
            otrzymywanie informacji handlowych bądź dotyczyć wszystkich celów
            przetwarzania danych. Wycofanie zgody co do wszystkich celów
            przetwarzania spowoduje, że konto Użytkownika zostanie usunięte ze
            strony internetowej, wraz ze wszystkimi wcześniej przetwarzanymi
            przez administratora danymi osobowymi Użytkownika. Wycofanie zgody
            nie wpłynie na już dokonane czynności.
          </p>
          <p>
            4. Użytkownik może w każdej chwili żądać, bez podawania przyczyny,
            aby administrator usunął Jego dane. Żądanie usunięcia danych nie
            wpłynie na dotychczas dokonane czynności. Usunięcie danych oznacza
            jednoczesne usunięcie konta Użytkownika, wraz ze wszystkimi
            zapisanymi i przetwarzanymi do tej pory przez administratora danymi
            osobowymi. 5. Użytkownik może w każdej chwili wyrazić sprzeciw
            przeciwko przetwarzaniu danych osobowych, zarówno w zakresie
            wszystkich przetwarzanych przez administratora danych osobowych
            Użytkownika, jak również jedynie w ograniczonym zakresie np. co do
            przetwarzania danych w konkretnie wskazanym celu. Sprzeciw nie
            wpłynie na dotychczas dokonane czynności. Wniesienie sprzeciwu
            spowoduje usunięcie konta Użytkownika, wraz ze wszystkimi zapisanymi
            i przetwarzanymi do tej pory, przez administratora, danymi
            osobowymi.
          </p>
          <p>
            6. Użytkownik może zażądać ograniczenia przetwarzania danych
            osobowych, czy to przez określony czas, czy też bez ograniczenia
            czasowego, ale w określonym zakresie, co administrator będzie
            obowiązany spełnić. Żądanie to nie wpłynie na dotychczas dokonane
            czynności.
          </p>
          <p>
            7. Użytkownik może zażądać, aby administrator przekazał innemu
            podmiotowi, przetwarzane dane osobowe Użytkownika. Powinien w tym
            celu napisać prośbę do administratora, wskazując, jakiemu podmiotowi
            (nazwa, adres) należy przekazać dane osobowe Użytkownika oraz jakie
            konkretnie dane Użytkownik życzy sobie, żeby administrator
            przekazał. Po potwierdzeniu przez Użytkownika swojego życzenia,
            administrator przekaże, w formie elektronicznej, wskazanemu
            podmiotowi, dane osobowe Użytkownika. Potwierdzenie przez
            Użytkownika żądania jest niezbędne z uwagi na bezpieczeństwo danych
            osobowych Użytkownika oraz uzyskanie pewności, że żądanie pochodzi
            od osoby uprawnionej.
          </p>
          <p>
            8. Administrator informuje Użytkownika o podjętych działaniach,
            przed upływem miesiąca od otrzymania jednego z żądań wymienionych w
            poprzednich punktach.
          </p>
        </div>

        <div data-g="4 12@sm 6@md">
          <h2>Okres przechowywania danych osobowych</h2>
          <p>
            1. Zasadniczo dane osobowe są przechowywane tylko tak długo, jak to
            jest konieczne do wypełnienia zobowiązań umownych lub ustawowych,
            dla których zostały one zgromadzone. Dane te zostaną usunięte
            natychmiast, gdy ich przechowywanie nie będzie konieczne, w celach
            dowodowych, zgodnie z prawem cywilnym lub w związku z ustawowym
            obowiązkiem przechowywania danych.
          </p>
          <p>
            2. Informacje, dotyczące umowy, przechowuje się w celach dowodowych,
            przez okres trzech lat, począwszy od końca roku, w którym zakończono
            relacje handlowe z Użytkownikiem. Usunięcie danych nastąpi po
            upływie ustawowego terminu przedawnienia dochodzenia roszczeń
            umownych.
          </p>
          <p>
            3. Ponadto, administrator może zachować informacje archiwalne,
            dotyczące zawartych transakcji, gdyż ich przechowywanie jest
            związane z przysługującymi Użytkownikowi roszczeniami np. z tytułu
            rękojmi.
          </p>
          <p>
            4. Jeśli żadna umowa nie została zawarta, między Użytkownikiem i
            Właścicielem, dane osobowe Użytkownika są przechowywane do czasu
            usunięcia konta Użytkownika na stronie internetowej. Usunięcie konta
            może nastąpić w wyniku wysunięcia żądania przez Użytkownika,
            wycofania zgody na przetwarzanie danych osobowych, bądź też
            zgłoszenia sprzeciwu co do przetwarzania tych danych.
          </p>

          <h2>Powierzenie przetwarzania danych innym podmiotom</h2>

          <p>
            1. Administrator może powierzać przetwarzanie danych osobowych
            podmiotom współpracującym z administratorem, w zakresie niezbędnym
            dla realizacji transakcji np. w celu przygotowania zamówionego
            towaru oraz dostarczania przesyłek lub przekazywania informacji
            handlowych, pochodzących od administratora (ostatnie dotyczy
            Użytkowników, którzy wyrazili zgodę na otrzymywanie informacji
            handlowych).
          </p>
          <p>
            2. Poza celami, wskazanymi w niniejszej Polityce Prywatności, dane
            osobowe Użytkowników, nie będą w żaden sposób udostępniane osobom
            trzecim, ani przekazywane innym podmiotom, w celu przesyłania
            materiałów marketingowych tych osób trzecich.
          </p>
          <p>
            3. Dane osobowe Użytkowników strony internetowej nie są przekazywane
            poza obszar Unii Europejskiej.
          </p>
          <p>
            4. Niniejsza Polityka Prywatności jest zgodna z przepisami
            wynikającymi z art. 13 ust. 1 i ust. 2 rozporządzenia RODO.
          </p>

          <h2>Pliki Cookies</h2>

          <p>
            1. Strona internetowa używa plików cookies (ciasteczka) lub podobną
            technologię (dalej łącznie nazywane: c"ookies") do zbierania
            informacji o dostępie Użytkownika do strony internetowej (np. za
            pomocą komputera lub smartfonu) oraz jego preferencjach. Są one
            wykorzystywane m.in. w celach reklamowych i statystycznych oraz w
            celu dostosowania strony internetowej do indywidualnych potrzeb
            Użytkownika.
          </p>
          <p>
            2. Pliki cookies to fragmenty informacji, które zawierają unikalny
            kod referencyjny, który strona internetowa przesyła na urządzenie
            Użytkownika, w celu przechowywania, a czasem śledzenia informacji,
            dotyczących używanego urządzenia. Zwykle nie pozwalają one
            zidentyfikować osoby Użytkownika. Ich głównym zadaniem jest lepsze
            dopasowanie strony internetowej do Użytkownika.
          </p>
          <p>
            3. Niektóre z plików cookies, występujące na stronie internetowej,
            są dostępne tylko przez czas trwania danej sesji internetowej i
            wygasają po zamknięciu przeglądarki. Inne pliki cookies służą do
            zapamiętywania Użytkownika, który po powrocie na stronę internetową,
            jest na niej rozpoznawany. Są one wówczas zachowywane przed dłuższy
            czas.
          </p>
          <p>
            4. Pliki cookies używane na niniejszej stronie internetowej to: _ga
            zbierane w celu analityki, przechowywane są przez dwa lata na
            serwerach Google Analytics.
          </p>
          <p>
            5. Wszystkie pliki cookies, występujące na stronie internetowej, są
            ustalane przez administratora.
          </p>
          <p>
            6. Wszystkie pliki cookies, używane przez niniejszą stronę
            internetową, są zgodne z obowiązującym prawem Unii Europejskiej.
          </p>
          <p>
            7. Większość Użytkowników i niektórych przeglądarek mobilnych
            automatycznie akceptuje pliki cookies. Jeżeli Użytkownik nie zmieni
            ustawień, pliki cookies zostaną zapisane w pamięci urządzenia.
          </p>
          <p>
            8. Użytkownik może zmienić preferencje, dotyczące akceptacji plików
            cookies lub zmienić przeglądarkę, aby móc otrzymać za każdym razem
            stosowne powiadomienie, gdy funkcja cookies jest ustawiona. Aby
            zmienić ustawienia akceptacji cookies, należy dostosować ustawienia
            w przeglądarce.
          </p>
          <p>
            9. Warto pamiętać, że blokowanie lub usuwanie plików cookies może
            uniemożliwić pełne korzystanie ze strony internetowej.
          </p>
          <p>
            10. Pliki cookies będą wykorzystywane do niezbędnego zarządzania
            sesją, w tym: a. Tworzenia specjalnej sesji logowania dla
            Użytkownika strony internetowej, aby strona zapamiętała, że
            Użytkownik jest zalogowany, a jego żądania były dostarczane w sposób
            skuteczny, bezpieczny i spójny; b. Rozpoznawania Użytkownika, który
            już wcześniej odwiedził stronę internetową, co pozwala na
            identyfikację liczby unikalnych użytkowników, którzy skorzystali z
            serwisu i pozwala upewnić się co do wystarczającej pojemności
            serwisu dla liczby nowych użytkowników; c. Rozpoznawania, czy osoba
            odwiedzająca stronę internetową jest zarejestrowana na stronie
            internetowej; d. Rejestrowanie informacji z urządzenia Użytkownika,
            w tym: pliki cookies, adres IP i informacje o używanej przeglądarce,
            w celu możliwości diagnozowania problemów, administrowania i
            śledzenia Użytkowania witryny; e. Dostosowywania elementów układu
            szaty graficznej lub zawartości strony internetowej; f. Zbierania
            informacji statystycznych o tym, jak Użytkownik korzysta ze strony,
            w celu możliwości ulepszania witryny i stwierdzenia, które zakresy
            strony internetowej są najbardziej popularne dla Użytkowników.
          </p>
        </div>
      </div>
    </div>
  )
}

export default PricelistHero
